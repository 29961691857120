import React, { useEffect } from "react";
import "./Order.css";
import { DashHead, TableBody } from "../../components";
import { useGlobalContext } from "../../context/context";
import { ColorRing } from "react-loader-spinner";

const Order = () => {
  const adminToken = JSON.parse(sessionStorage.getItem("adminToken"));
  const {
    getAllDeposits,
    allDeposits,
    allUsers,
    getAllUsers,
    dLoading,
    usersLoading,
    widthDrawals,
    getAllWithdrawals,
    allLoans,
    getAllLoans,
    getAllKyc,
    allKYCs,
  } = useGlobalContext();

  useEffect(() => {
    getAllDeposits(adminToken);
    getAllUsers(adminToken);
    getAllWithdrawals(adminToken);
    getAllLoans(adminToken);
    getAllKyc(adminToken);
  }, []);

  const successfulDeposits = allDeposits?.filter(
    (item) => item.status === "true"
  );
  const approvedLoans = allLoans?.filter((loan) => loan.status === "true");
  const pendingLoans = allLoans?.filter((loan) => loan.status === "Pending");
  const failedDeposits = allDeposits?.filter(
    (item) => item.status === "Pending"
  );
  const pendingKYCs = allKYCs?.filter((item) => item.status === false);
  const approvedKYCs = allKYCs?.filter((item) => item.status === true);
  const successWithdrawals = widthDrawals?.filter(
    (item) => item.status === true
  );
  const pendingWithdrawals = widthDrawals?.filter(
    (item) => item.status === false
  );

  return (
    <section className="orders">
      <DashHead title={"Dashboard"} paragraph={"View All Details here"} />
      <div className="order_statistics">
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">Pending Deposits</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              failedDeposits?.length
            )}
          </h3>
        </div>
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">Successful Deposits</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              successfulDeposits?.length
            )}
          </h3>
        </div>
      </div>
      <div className="order_statistics">
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">Users</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              allUsers?.length
            )}
          </h3>
        </div>
        <div className="order_statistics_item">
          <p className="dashboard_paragraph"> Deposits</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              allDeposits?.length
            )}
          </h3>
        </div>
      </div>
      <div className="order_statistics">
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">All Pending Withdrawals</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              pendingWithdrawals?.length
            )}
          </h3>
        </div>
        <div className="order_statistics_item">
          <p className="dashboard_paragraph"> Successful Withdrawals</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              successWithdrawals?.length
            )}
          </h3>
        </div>
      </div>
      <div className="order_statistics">
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">KYCs</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              approvedKYCs?.length
            )}
          </h3>
        </div>
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">Approved Loans</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              approvedLoans?.length
            )}
          </h3>
        </div>
      </div>
      <div className="order_statistics">
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">Pending KYCs</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              pendingKYCs?.length
            )}
          </h3>
        </div>
        <div className="order_statistics_item">
          <p className="dashboard_paragraph">Pending Loans</p>
          <h3 className="dashboard_header_text">
            {dLoading ? (
              <ColorRing
                visible={true}
                height="35"
                width="35"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black", "black", "black", "black", "black"]}
              />
            ) : (
              pendingLoans?.length
            )}
          </h3>
        </div>
      </div>
      <div className="food_item_table">
        <div className="food_item_table_header">
          <h4>All Users</h4>
        </div>
        <TableBody
          symbol={""}
          path={"user-details"}
          order={"Order"}
          tableData={allUsers.slice(0, 2)}
          loading={usersLoading}
        />
      </div>
    </section>
  );
};

export default Order;
